<template>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="电子样册列表" name="first">
          <tabs1 v-if="activeName == 'first'" />
        </el-tab-pane>
        <el-tab-pane label="电子样册分类" name="second"> 
          <tabs2 v-if="activeName == 'second'" :drafts='true' />
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  
  <script>
  import tabs1 from './tabs1';
  import tabs2 from './tabs2';
  export default {
    name: "spike",
    components:{
        tabs1,
        tabs2
    },
    data() {
      return {
        activeName: "first",
      }
    },
    methods: {
      handleClick(tab) {
        const { name } = tab;
        this.activeName = name;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>